import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Directions = () => (
  <Layout>
    <SEO
      title="Directions"
      keywords={['duck', 'hunt', 'Georgia', 'Hartwell']}
    />
    <div
      style={{
        height: '100vh',
        width: '100%',
        padding: '10px 10px 0',
        margin: '0 15px 10px',
      }}
    >
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.9500398591604!2d-82.85310472506673!3d34.31916100855345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88587f6cf4f4e809%3A0xe5ed3b78e76f87ca!2s4183+Liberty+Hill+Rd%2C+Hartwell%2C+GA+30643!5e0!3m2!1sen!2sus!4v1564504079164!5m2!1sen!2sus"
        style={{ border: 0, minWidth: '95vw', minHeight: '85vh' }}
        allowFullScreen
      />
    </div>
  </Layout>
);

export default Directions;
